.dashboard-main {
  display: flex;
  flex-direction: column;

  @media (min-width: 850px) {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
  }
}
.first-half {
  flex: 0 0 30%;
}
.second-half {
  flex: 0 0 70%;
}

.dashboard-inputs {
  display: flex;
  /* grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); */
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 50px;
  @media (max-width: 850px) {
    flex-direction: row;
  }
}

.table-dashboard {
  overflow-x: scroll;
}

.dashboard-cards {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  @media (min-width: 850px) {
    flex-direction: row;
  }
}
.graphics-first-second {
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media (min-width: 850px) {
    flex-direction: row;
  }
}

.dashboard-container {
  padding: 1.5rem;
  background-color: #f8f9fa;
  min-height: calc(100vh - 64px);
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
}

.dashboard-title {
  margin: 0;
  font-size: 1.75rem;
  color: #333;
}

.dashboard-wrapper {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 992px) {
  .dashboard-wrapper {
    grid-template-columns: 300px 1fr;
  }
}

.dashboard-sidebar {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.filter-card,
.equipment-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.filter-title,
.equipment-title {
  margin: 0;
  padding: 1rem;
  background-color: #f3f3f3;
  border-bottom: 1px solid #e0e0e0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.filter-content,
.equipment-content {
  padding: 1rem;
}

.equipment-table-container {
  max-height: 400px;
  overflow-y: auto;
}

.equipment-table {
  width: 100%;
  border-collapse: collapse;
}

.equipment-table th,
.equipment-table td {
  padding: 0.75rem 0.5rem;
  text-align: left;
  border-bottom: 1px solid #e0e0e0;
}

.equipment-table th {
  font-weight: 600;
  background-color: #f9f9f9;
  position: sticky;
  top: 0;
  z-index: 1;
}

.dashboard-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 1.25rem;
  margin-bottom: 1.5rem;
}

.chart-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
  gap: 1.5rem;
}

.full-width {
  grid-column: 1 / -1;
}

.chart-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.chart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e0e0e0;
}

.chart-header h3 {
  margin: 0;
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.chart-legend {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #666;
}

.chart-content {
  padding: 1rem;
  height: 300px;
  position: relative;
  overflow: auto;
  scrollbar-width: thin;
}

.chart-content-donut {
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 768px) {
  .chart-grid {
    grid-template-columns: 1fr;
  }

  .dashboard-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .dashboard-refresh {
    width: 100%;
  }
}

/* Maintain refresh button styles from Relatorios dashboard */
.dashboard-refresh {
  display: flex;
  justify-content: flex-end;
}

.refresh-info {
  display: flex;
  align-items: center;
  gap: 1rem;
  background: white;
  padding: 0.5rem 1rem;
  border-radius: 9999px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.last-update {
  color: #6b7280;
  font-size: 0.875rem;
}

.refresh-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0 !important;
  border-radius: 50% !important;
  background: transparent !important;
  border-color: #e5e7eb !important;
  color: #6b7280 !important;
  transition: all 0.2s ease !important;
}

.refresh-button:not(:disabled):hover {
  background: #f9fafb !important;
  color: #374151 !important;
  border-color: #d1d5db !important;
  transform: scale(1.05);
}

.refresh-button:not(:disabled):active {
  transform: scale(0.95);
}

.refresh-button.p-button-loading {
  background: #f3f4f6 !important;
}

/* Add skeleton styles */
.skeleton-card {
  background: white;
}

.skeleton-icon {
  overflow: hidden;
}

.skeleton-chart {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

/* Add animation for skeletons */
.p-skeleton {
  background: linear-gradient(90deg, #f0f0f0 25%, #e8e8e8 37%, #f0f0f0 63%);
  background-size: 400% 100%;
  animation: p-skeleton-animation 1.4s ease infinite;
}

@keyframes p-skeleton-animation {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

/* Add a transition for content */
.dashboard-cards,
.chart-card,
.equipment-content {
  transition: opacity 0.3s ease;
}

.dashboard-container {
  min-height: calc(100vh - 64px);
}

/* Add styles for chart actions (buttons, etc.) */
.chart-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* Improve scrolling in chart containers */
.chart-content {
  overflow: auto;
  scrollbar-width: thin;
}

.chart-content::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

.chart-content::-webkit-scrollbar-thumb {
  background-color: #ddd;
  border-radius: 6px;
}

.chart-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

/* Button styling */
.p-button-sm {
  font-size: 0.75rem !important;
  padding: 0.25rem 0.5rem !important;
}
