.checklists-table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.detail-item {
  margin-bottom: 10px;
}

.detail-item strong {
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .checklists-table-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }
  .checklists-table-header-periodo {
    display: flex;
  }
}
