.detail-item {
  margin-bottom: 10px;
}

.detail-item strong {
  font-weight: 600;
}

.card-title {
  font-weight: 600;
  margin-bottom: 15px;
}

.card {
  border: 1px solid #dee2e6;
  border-radius: 6px;
  background-color: #f8f9fa;
  transition: box-shadow 0.3s;
}

.card:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}
