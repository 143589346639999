.checklists-table-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .checklists-table-header {
    flex-direction: column;
  }
}
