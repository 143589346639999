.reports-drawer .p-sidebar-content {
  padding: 0;
}

.reports-drawer .table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
}

.reports-drawer .p-datatable-header {
  background: #f8f9fa;
  border: none;
}

.reports-drawer .p-selectbutton {
  display: flex;
  margin-bottom: 1rem;
}

.reports-drawer .p-selectbutton .p-button {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  color: #495057;
}

.reports-drawer .p-selectbutton .p-button.p-highlight {
  background-color: #0a073b;
  border-color: #0a073b;
  color: #ffffff;
}
